import * as MaterialUICore from '@material-ui/core';
import * as MaterialUIUtils from '@material-ui/utils';
import * as MaterialUILab from '@material-ui/lab';
import * as colorManipulator from '@material-ui/core/styles/colorManipulator';
import { fade } from '@material-ui/core/styles';
import * as MaterialUIStyles from '@material-ui/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

export default {
    ...MaterialUICore,
    ...MaterialUILab,
    utils: {
        ...MaterialUIUtils,
    },
    styles: {
        ...MaterialUIStyles,
        colorManipulator: colorManipulator,
        fade: fade,
        MuiThemeProvider: MuiThemeProvider,
        createMuiTheme: createMuiTheme,
    }
};