(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["Material_UI"] = factory(require("React"), require("ReactDOM"));
	else
		root["PSLIB"] = root["PSLIB"] || {}, root["PSLIB"]["React"] = root["PSLIB"]["React"] || {}, root["PSLIB"]["React"]["Material_UI"] = factory(root["PSLIB"]["ReactCommon"]["React"], root["PSLIB"]["ReactCommon"]["ReactDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__3__) {
return 